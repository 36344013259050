#tiles-component{
    width: 100%;
}

.tiles-title {
    margin: auto;
    text-align: center;
}

.tiles-container {
    display: flex;
    flex-direction: row;
    margin: 2.5rem auto;
    justify-content: space-around;
    flex-wrap: wrap;
}


.tile {
    display: flex;
    width:30%;
    padding: 1rem auto;
    flex-direction: column;
    margin: 1rem auto ;
    background: rgba(42, 53, 81, 0.3);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(42, 53, 81, 0.3);
}

.tile-content {
    display: flex;
    flex: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #426296;
    background: rgba(42, 53, 81, 0.3);
    border-radius: 16px 16px 0 0;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(42, 53, 81, 0.3);
    text-align: center;
}

.tile-content img {
    width: 100%;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
    max-height: 23rem;
}

.tile-footer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    flex-direction: column;
    background: rgba(42, 53, 81, 0.3);
    border-radius: 0 0 16px 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(42, 53, 81, 0.3);
}

.tile-footer a {
    width: 100%;
    margin: 0.5rem 0;
}

.tile-footer button {
    padding: 1rem;
    background-color: #900137;
    cursor: pointer;
    font-weight: bold;
    color: #fff;
    width: 100%;
    border-radius: 10px;
}

.tile-footer button:hover {
    background-color: #426296;
}

.tile-footer button:active {
    position: relative;
    top: 1px;
}

@media screen and (max-width: 1320px) {
    .tiles-container {
        flex-direction: column;
    }

    .tile {
        width:clamp(1rem, 90%, 50rem)   
    }
}