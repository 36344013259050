/* Contains the entire nav bar (logo + items) */
.nav-div {
    display: flex;
    flex-direction: row;
    background-color: #39284c;
}

/* Contains just the logo, child of .nav-div */
.nav-logo-div {
    flex: 1;
}

/* The actual logo img child of .nav-logo-div */
.nav-logo {
    padding: 1rem;
    width: 2rem;
}

/* A div containing a list of items, second column in flex box, child of nav-div. */
.nav-items {
    display: flex;
    flex: 10;
    justify-content: flex-end;
    margin: 0 1rem 0 0;
}

/* An li within .nav-items */
.nav-item {
    display: inline;
    padding: 1rem;
}

/* Links in the nav-item */
.nav-item a {
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
}

.nav-item a:hover {
    color: #900137;
}

#hamburger {
    display: none;
}

/* link underline animation */
.underline {
    position: relative;
}

.underline::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 2px;
    background-color: #900137;
    transition: width 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

@media (hover: hover) and (pointer: fine) {
    .underline:hover::before {
        left: 0;
        right: auto;
        width: 100%;
    }
}

/* Styling for mobile nav bar when closed */
.nav-div-responsive-closed {
    display: flex;
    flex-direction: row;
    background-color: #11cfc5;
    background-color: #39284c;
}

.nav-logo-div-closed {
    flex: 1;
}

.nav-hamburger-responsive-closed {
    justify-content: flex-end;
    margin: 0 1rem 0 0;
}

.nav-hamburger-responsive-closed img {
    width: 4rem;
    height: 4rem;
}


/* Styling for mobile nav bar when open */

.nav-div-responsive-open {
    display: flex;
    flex-direction: column;
    background-color: #39284c;
}

.nav-top-bar-responsive-open {
    display: flex;
    flex-direction: row;
}

.nav-logo-div-open {
    flex: 1;
}

.nav-hamburger-responsive-open {
    justify-content: flex-end;
    margin: 0 1rem 0 0;
}

.nav-hamburger-responsive-open img {
    width: 4rem;
    height: 4rem;

}

.nav-items-responsive-open {
    display: flex;
    flex: 10;
    justify-content: center;
    width:100%
}

.nav-items-list-responsive-open {
    list-style: none;
    padding: 0;
}

.nav-item-responsive-open {
    padding: 1rem 0;
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
}

.nav-item-responsive-open a {
    color: white;
    font-size: 1.5rem;
    text-decoration: none;
}

.nav-item-responsive-open a:hover {
    color: #900137;
}