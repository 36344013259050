/* Contains media and text divs */
.container-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 2.5rem auto;
    font-family: "Brandon Grotesque";
}

/* Contains title div and tech div, left column*/
.media-div {
    flex: 1;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.6) 100%), url("/public/Commodore.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

/* Contains name and tagline */
.title-div {
    border-left: 5px solid #fff;
    margin: 2rem 3rem;
    flex:1;
}

.title-div h1 {
    font-size:5rem;
    margin: 0 1rem;
    color: #fff;
    text-align: left;
}

.title-div h2 {
    font-family: "OpenSans";
    font-size: 1rem;
    margin: 0 1.5rem;
    color: #fff;
    text-align: left;
}

/* Contains text, second child of container-div*/
.content-div {
    flex: 1;
}

.media-img {
    width: 100%
}

.socials-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-wrap: nowrap;
}

.socials-list {
    list-style: none;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.socials-item {
    display: inline;
    margin: 0.5rem;
}

.socials-item a img {
    max-width: 12vw;
}

#cv-buttons {
    display: flex;
    flex-direction: row;
    width: 75%;
}

#cv-buttons a {
    flex: 1;
    margin: 1rem;
}

#cv-buttons button {
    padding: 1rem;
    width: 100%;
    background-color: #900137;
	border:2px solid #0b535e;
	cursor:pointer;
	font-weight:bold;
    color: #fff;
    font-family: 'Brandon Grotesque';
    font-size: 1.5rem;
}

#cv-buttons button:hover {
    background-color:#426296;
}
#cv-buttons button:active {
    position:relative;
	top:1px;
}

@media screen and (max-width: 960px) {
    .container-div {
        flex-direction: column;
    }

    #cv-buttons {
        flex-direction: column;
        width: 100%;
    }

    #cv-buttons a {
        margin: 1rem;
    }

    .content-div {
        margin-top: 2.5rem;
    }

    .socials-item a img {
        max-width: 25vw;
    }
}

