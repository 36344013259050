/*parent div for component, contains avatar-div and overlay-content-div */

.media-container {
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0.6) 100%), url("/public/Arcade.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row;
    padding: 10rem;
    margin: 2.5rem auto;
}

/* child of media-container, contains left side elements */
.avatar-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

/* The header above the avatar */
.avatar-div h2 {
    color: #fff;
    text-align: center;
}

/* The avatar img */
.avatar {
    width: 75%;
    border-radius: 50px;
}

/* description p under thr avatar */
#avatar-description {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    text-align: center;
}

/* Right column */
.overlay-content-div {
    flex: 2;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}


/* short paragraph on the right side */
#about-text {
    font-size: 1.5rem;
    color: #fff;
    text-align: center;
    padding: 0 1rem;
    font-family: "OpenSans";
    text-shadow:
        0.05em 0 black,
        0 0.05em black,
        -0.05em 0 black,
        0 -0.05em black,
        -0.05em -0.05em black,
        -0.05em 0.05em black,
        0.05em -0.05em black,
        0.05em 0.05em black;
}

/* Mobile */

@media screen and (max-width: 1430px) {
    .media-container {
        flex-direction: column;
        padding: 1rem;
    }

    .overlay-content-div {
        flex-direction: column;
        flex: 1;
    }

    .avatar-div {
        flex: 1;
        width: 100%;
        flex-direction: column;
    }

    .avatar {
        width: 75%;
    }

    #about-text {
        font-size: 1.5rem;

    }
}