@font-face { font-family: "Brandon Grotesque"; src: url('./fonts/BrandonGrotesque.otf'); } 
@font-face { font-family: "OpenSans"; src: url('./fonts/OpenSans.ttf'); } 

body {
  font-family: "Brandon Grotesque";
  margin: 0;
  background: rgb(235,192,235);
  background: radial-gradient(circle, rgba(235,192,235,1) 0%, rgba(237,228,237,1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
